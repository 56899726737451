import type { ItemModel, DataItemModel } from './types.ts';
import type { DataObjectField } from './DataObject.Fields.ts';
import type { DataItemOptions, DataItemConstructor } from './DataObject.Item.ts';

import {default as DataItem, getDataItemModel } from './DataObject.Item.ts';


export default class DataObjectStorage<T extends ItemModel = ItemModel> {
    //private _getDataObject: () => DataObject<T>;
    private _items: DataItemModel<T>[];
    private _newItemOptionsFactory: () => DataItemOptions<T>;
    private _fields: DataObjectField[];
    private _updatedDebounce: null | number = null;
    private _updated: Date | null = null;
    /** When true will stop updating storage updated date */
    private _stopStorageUpdateEvents = false;
    private _dataItemModel: DataItemConstructor<T>;
    /** When true will push new records to the end of the storage instead of unshifting them */
    createNewAtTheEnd: boolean = false;

    /** Indexes mapped to DataItems */
    itemsMap: Map<number, DataItemModel<T>> = new Map();
    /** Ids mapped to item indexes */
    itemsIdMap: Map<number, number> = new Map();

    /** Stored items array */
    get data() { return this._items; }  
    get items() { return this._items; }
    /** Array of items with changes */
    get changes() { return this.data.filter(x => x.hasChanges); }
    /** Indicates that the storage has an item with changes */
    get hasChanges() { return this.data.some(x => x.hasChanges); }
    /** Date value that gets updated after storage indexes change */
    get updated() { return this._updated; }
    /** Function for getting default item options */
    get newItemOptionsFactory() { return this._newItemOptionsFactory; }
    /** Update item setters and set values with change tracking */
    get updateOrExtendItem() { return this._updateOrExtendItem; }

    /** Internal DataItem constructor. This is an unique DataItem class per storage */
    get DataItemModel() { return this._dataItemModel; }

    

    constructor(pOptions: {
        newItemOptionsFactory: () => DataItemOptions<T>
        createNewAtTheEnd?: boolean
        fields: DataObjectField[],
        dataObjectId?: string,
    }) {
        this._newItemOptionsFactory = pOptions.newItemOptionsFactory;
        this.createNewAtTheEnd = pOptions.createNewAtTheEnd ?? false;
        this._fields = pOptions.fields;
        this._items = [];

        this._dataItemModel = getDataItemModel<T>({
            prefix: pOptions.dataObjectId ?? '',
            fields: this._fields,
            keys: this._fields.map(field => field.name)
        })
    }

    /**
     * Add item on a specific index. If item already exists, 
     * then will update its values.
     */
    addItem(pItem: T, pIndex: number, pSkipSetters = false) {
        if (this._items[pIndex]) {
            this._items[pIndex].extendItem(pItem, pSkipSetters);
        } else if (pItem instanceof DataItem) {
            pItem.index = pIndex;
            this.data[pIndex] = pItem as DataItemModel<T>;
        } else {
            this._items[pIndex] = (this._createDataItem(pIndex, pItem, this._newItemOptionsFactory())) as DataItemModel<T>;
            this._items[pIndex].initialize();

            this.itemsMap.set(pIndex, this._items[pIndex]);
            if (this._items[pIndex].ID) {
                this.itemsIdMap.set(this.data[pIndex].ID, pIndex);
            }
        }
        this._storageUpdated();
        return this._items[pIndex];
    }

    updateItem(pIndex: number, pValue: Partial<T>, pSkipChangeDetection = false) {
        const item = this.data[pIndex];
        if (item == null) { return undefined; }
        this._updateOrExtendItem(pIndex, pValue);

        if (item.ID && !this.itemsMap.has(item.ID)) {
            this.itemsIdMap.set(item.ID, pIndex);
        }

        if (pSkipChangeDetection) {
            item.reset();
        }

        return this.data[pIndex];
    }

    mergeChanges(pIndex: number, pValue: Partial<T>) {
        if (pValue['Updated']) {
            this.data[pIndex].item['Updated'] = pValue['Updated'];
        }
        // const item = this.data[pIndex];
        // if (item == null) { return undefined; }
        // const valuesFromTriggers: Partial<T> = {};
        // const savedValues: Partial<T> = {};
        // Object.keys(pValue).forEach((key: keyof T & string) => {
        //     if (pFields.includes(key) && !['Updated'].includes(key)) {
        //         savedValues[key] = pValue[key];
        //     } else {
        //     valuesFromTriggers[key] = pValue[key];
        //     }
        // });
        // if (Object.keys(changes).length) {
        //     this._updateOrExtendItem(pIndex, changes);
        // }
        // item.reset();
        // item.oldValues = savedValues;

        // item._updateStates();
        // return item;
    }

    updateItemsById(pId: number, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        if (!this.itemsIdMap.has(pId)) {
            return undefined;
        } else {
            return this.updateItem(this.itemsIdMap.get(pId)!, pValue, pSkipChangeDetection);
        }
    }
    updateItemById(pId: number, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        if (!this.itemsIdMap.has(pId)) {
            return undefined;
        } else {
            return this.updateItem(this.itemsIdMap.get(pId)!, pValue, pSkipChangeDetection);
        }
    }

    updateItemByPrimKey(pPrimKey: string, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        const item = this.data.find(item => item.PrimKey === pPrimKey);
        if (item == null) {
            return undefined;
        } else {
            return this.updateItem(item.index, pValue, pSkipChangeDetection);
        }
    }

    setItems(pItems: T[], pClear = false, pSkip = 0) {
        const returnData: DataItemModel<T>[] = [];
        this._stopStorageUpdateEvents = true;
        if (pClear) {
            this.clearItems();
        } else if (pSkip === 0) {
            pSkip = this._items.length;
        }
        const fields = new Set<string>();
        for (let field of this._fields) {
            fields.add(field.name);
        }
        if (pItems[0]) {
            for (let key in pItems[0]) {
                fields.add(key);
            }
        }
        this._dataItemModel.updateSetters(Array.from(fields));
        
        for (let i = 0; i < pItems.length; i++) {
            returnData.push(this.addItem(pItems[i], i + pSkip, true));
        }
        this._stopStorageUpdateEvents = false;
        this._storageUpdated();

        return returnData;
    }

    removeItem(pIndex: number) {
        this._items.splice(pIndex, 1);
        this.reindex();
        this._storageUpdated();
    }

    removeItemsByPrimKeys(pPrimKeys: string[]) {
        const rowsToRemove = this._items.filter(row => pPrimKeys.includes(row.PrimKey));
        for (let i = rowsToRemove.length-1; i >= 0; i--) {
            this._items.splice(rowsToRemove[i].index, 1);
        }
        this.reindex();
        this._storageUpdated();
    }

    /** Clear all items from the storage */
    clearItems() {
        this._items.splice(0, this._items.length);
        this.itemsMap.clear();
        this.itemsIdMap.clear();
        this._storageUpdated();
    }

    /**
     * Set every item's index to their place in the storage array. 
     * Used when moving items around or unshifting them
     */
    reindex() {
        for (let i = 0; i < this._items.length; i++) {
            this._items[i].index = i;
        }
        this._storageUpdated();
    }

    /** Create item model with default field values */
    getEmptyItem(): T {
        const item: Record<string, any> = {};
        for (const field of this._fields) {
            if (field.defaultValue !== undefined) {
                item[field.name] = field.defaultValue;
            } else if (typeof field.defaultValueFunction === 'function') {
                item[field.name] = field.defaultValueFunction();
            } else {
                item[field.name] = null;
            }
        }
        return item as T;
    }

    /** Create new item in the storage */
    createNew(pOptions?: Partial<T> & { current?: boolean }, pSkipExtend = false) {
        const item = this._createNewItem(this.getEmptyItem());
        item.state.isNewRecord = true;
        if (pOptions?.current) {
            item.current = pOptions.current;
            delete pOptions.current;
        }
        if (pOptions && Object.keys(pOptions).length > 0) {
            if (pSkipExtend) {
                item.extendItem(pOptions as any, true);
            } else {
                this._updateOrExtendItem(item.index, pOptions);
            }
        } else {
            item.state.isEmpty = true;
        }
        return this._items[item.index];
    }

    getItem(pIndex: number) {
        return this.data[pIndex];
    }
    getItemByPrimKey(pPrimKey: string) {
        return this.data.find(item => item?.primKey === pPrimKey);
    }
    getItemByKey(pKey: string) {
        return this.data.find(item => item?.key === pKey);
    }
    getItemById(pId: string|number) {
        return this.data.find(item => item?.ID === pId);
    }
    getItemByField<K extends keyof T & string>(pField: K, pValue: T[K]) {
        return this.data.find(item => item[pField] === pValue);
    }
    cancelChanges(pIndex?: number, pKey?: keyof T & string) {
        if (pIndex != null) {
            this.getItem(pIndex)?.cancelChanges(pKey);
        } else {
            for (const item of this.changes) {
                item.cancelChanges(pKey);
            }
        }
    }

    toJSON() {
        return this.data.map(x => x.item);
    }

    /**
     * No longer necessary to use for reactivity. Items can be updated directly
     * @depricated
     */
    // @ts-ignore
    private updateItemProps(pIndex: number, pValue: T) {
        const item = this.data[pIndex];
        if (item == null) { return; }
        for (const key in pValue) {
            if (item.hasOwnProperty(key)) {
                (item as any)[key] = pValue[key];
            }
        }
    }

    /** Create DataItem from the provided item model and push/unshift it in the storage */
    private _createNewItem(pItem: T) {
        if (this.createNewAtTheEnd) {
            this._items.push(this._createDataItem(this._items.length, pItem, this._newItemOptionsFactory()) as DataItemModel<T>);
            this._items.at(-1)!.initialize();
            this._storageUpdated();
            return this._items.at(-1)!;
        } else {
            this._items.unshift(this._createDataItem(0, pItem, this._newItemOptionsFactory()) as DataItemModel<T>);
            this._items.at(0)!.initialize();
            this.reindex();
            return this._items.at(0)!;
        }
    }

    /** Update item and initialize setters for new values */
    private _updateOrExtendItem(pIndex: number, pOptions: Partial<T>) {
        for (const key in pOptions) {
            if (!this._items[pIndex].hasOwnProperty(key)) {
                this._items[pIndex].updateSetter(key);
            }

            (this._items[pIndex] as any)[key] = pOptions[key];
        }
        return this._items[pIndex];
    }

    /** Update the storage.updated value. Used to trigger watchers that are targeting this storage.updated  */
    _storageUpdated() {
        if (this._stopStorageUpdateEvents) { return; }

        if (this._updatedDebounce) { window.clearTimeout(this._updatedDebounce); }

        this._updatedDebounce = window.setTimeout(() => {
            this._updated = new Date();
            this._updatedDebounce = null;
        }, 50);
    }
    
    protected _createDataItem(...args: ConstructorParameters<typeof DataItem<T>>) {
        if (this._customDataItemConstructor) {
            return this._customDataItemConstructor(...args);
        } else {
            return new this._dataItemModel(...args);
            // return new DataItem(...args);
        }
    }

    private _customDataItemConstructor?: (...args: ConstructorParameters<typeof DataItem<T>>) => DataItem<T>
    setDataItemConstructor(pConstructor: ((...args: ConstructorParameters<typeof DataItem<T>>) => DataItem<T>) | null) {
        this._customDataItemConstructor = pConstructor ?? undefined;
    }
}
